import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import { ReportingApi } from "../../../api/reportingApi";
import { RepresentativesApi } from "../../../api/representativesApi";
import { QueryKeys, safePercent } from "../../../helpers";
import { useMapVerbiage } from "../../../hooks/verbiageHooks";
import { type AgentData, KeyedArray, type ReportingFilter } from "../../../models";
import { ChartSkeleton, Column, DataGridClient } from "../../basic";

export interface Props {
    filterData: ReportingFilter;
}

export function AgentLeaderboardWidget(props: Props) {
    const { filterData } = props;

    const mapVerbiage = useMapVerbiage();

    const repsQuery = useQuery({ queryKey: [QueryKeys.REPS], queryFn: RepresentativesApi.getAllBasicInfo });
    const reps = useMemo(() => new KeyedArray(repsQuery.data ?? [], (r) => r.repId), [repsQuery.data]);

    const columns = useMemo(
        () => [
            new Column(mapVerbiage("Rep")).withFormat((r: AgentData) =>
                reps.has(r.repId)
                    ? `${reps.get(r.repId)?.lastName ?? ""}, ${reps.get(r.repId)?.lastName ?? ""} (${r.repId})`
                    : r.repId,
            ),
            new Column("Total TPVs", "totalSales"),
            new Column("Successful", "goodSales"),
            new Column("Pending", "pendingSales"),
            new Column("Failed", "noSales"),
            new Column("Successful GPS %").withFormat((r: AgentData) =>
                safePercent(r.goodWebSalesWithGps, r.goodWebSales, 0),
            ),
        ],
        [mapVerbiage, reps],
    );
    const agentLeaderboardQuery = useQuery({
        queryKey: [QueryKeys.AGENT_LEADERBOARD, filterData],
        queryFn: () => ReportingApi.getAgentLeaderboard(filterData),
    });

    if (agentLeaderboardQuery.isLoading) return <ChartSkeleton />;

    return (
        <DataGridClient
            data={agentLeaderboardQuery.data ?? []}
            columns={columns}
            loading={agentLeaderboardQuery.isLoading}
            pageSizeOptions={[10]}
        />
    );
}
