import { useContext } from "react";

import { UserContext } from "../contexts/userContext";
import { hasValue } from "../helpers";
import { type VerificationDto } from "../models";

function mapVerbiageByMode(hasCampaigns: boolean, verbiage: VerbiageTypes, voterMode?: boolean) {
    voterMode = hasValue(voterMode) ? voterMode : hasCampaigns;
    switch (verbiage) {
        case "Customer":
            return voterMode ? "Voter" : "Customer";
        case "Rep":
            return voterMode ? "Canvasser" : "Rep";
        case "DateOfSale":
            return voterMode ? "Date of Signing" : "Date of Sale";
        case "SaleId":
            return voterMode ? "Signature ID" : "Sale ID";
        case "Campaign":
            return voterMode ? "Campaign" : "Brand";
        default:
            return "";
    }
}

export function useMapVerbiage() {
    const { hasCampaigns } = useContext(UserContext);

    return (verbiage: VerbiageTypes, v?: VerificationDto) => {
        const voterMode = v ? hasValue(v?.campaignId) : undefined;

        return mapVerbiageByMode(hasCampaigns, verbiage, voterMode);
    };
}

export function useReplaceVerbiage() {
    const mapVerbiage = useMapVerbiage();

    return (verbiage: VerbiageTypes, text: string, v?: VerificationDto) => {
        const mapped = mapVerbiage(verbiage, v);
        return text.replaceAll(verbiage, mapped);
    };
}

type VerbiageTypes = "Customer" | "Rep" | "Campaign" | "DateOfSale" | "SaleId";
