import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { type FC } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { Auth } from "./components/auth/Auth";
import { AlertsBanner, ToastProvider } from "./components/basic";
import { CheckInProviders } from "./components/checkIn/checkInProviders";
import { Footer } from "./components/footer";
import { UserProvider } from "./contexts/userContext";
import { MS_IN, RouteValues, setCookieName } from "./helpers";
import { AuditPage } from "./pages/audit";
import CampaignsPage from "./pages/campaigns";
import { CheckInPage } from "./pages/checkIn";
import FormsPage from "./pages/forms";
import Generated from "./pages/generated";
import Historical from "./pages/historical";
import Home from "./pages/home";
import RealTime from "./pages/realtime";
import { Reports } from "./pages/reports";
import Representatives from "./pages/representatives";
import { ScanPage } from "./pages/scan";
import { StartAuditPage } from "./pages/startAudit";
import ChangePassword from "./pages/user/changePassword";
import ForgotPassword from "./pages/user/forgotPassword";
import Login from "./pages/user/login";
import { Settings } from "./pages/user/settings";
import Users from "./pages/users";

function handleDarkMode() {
    if (
        localStorage.theme === "dark" ||
        (!("theme" in localStorage) && window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
        document.documentElement.classList.add("dark");
    } else {
        document.documentElement.classList.remove("dark");
    }
}
export const App: FC = () => {
    const queryClient = new QueryClient({
        defaultOptions: { queries: { staleTime: MS_IN.MINUTE * 5, retry: false } },
    });

    setCookieName("avlLoginPayload");
    handleDarkMode();

    return (
        <QueryClientProvider client={queryClient}>
            <UserProvider>
                <ToastProvider>
                    <AlertsBanner />
                    <BrowserRouter>
                        <Routes>
                            <Route path={RouteValues.LOGIN} element={<Login />} />
                            <Route
                                path={RouteValues.CHANGE_PASSWORD}
                                element={<Auth page={<ChangePassword />} allowAnonymous isChangingPassword />}
                            />
                            <Route path={`${RouteValues.FORGOT_PASSWORD}`} element={<ForgotPassword />} />
                            <Route
                                path={`${RouteValues.FORGOT_PASSWORD}/:forgotKey`}
                                element={<Auth page={<ChangePassword />} allowAnonymous />}
                            />
                            <Route path={RouteValues.HOME} element={<Auth page={<Home />} />} />
                            <Route path={RouteValues.REPS} element={<Auth page={<Representatives />} />} />
                            <Route path={RouteValues.USERS} element={<Auth page={<Users />} />} />
                            <Route path={RouteValues.FORMS} element={<Auth page={<FormsPage />} />} />
                            <Route path={RouteValues.CAMPAIGNS} element={<Auth page={<CampaignsPage />} />} />
                            <Route path={RouteValues.CHECK_IN} element={<Auth page={<CheckInProviders />} />}>
                                <Route path="" element={<CheckInPage />} />
                                <Route path=":id" element={<ScanPage />} />
                            </Route>
                            <Route path={RouteValues.AUDIT} element={<Auth page={<StartAuditPage />} />} />
                            <Route path={`${RouteValues.AUDIT}/:id`} element={<Auth page={<AuditPage />} />} />
                            <Route path={RouteValues.REPORTS} element={<Auth page={<Reports />} />}>
                                <Route path={RouteValues.REALTIME} element={<RealTime />} />
                                <Route path={RouteValues.HISTORICAL} element={<Historical />} />
                                <Route path={RouteValues.GENERATED} element={<Generated />} />
                                <Route path="" element={<Navigate to={RouteValues.REALTIME} />} />
                            </Route>
                            <Route path={RouteValues.SETTINGS} element={<Auth page={<Settings />} />} />
                            <Route path="*" element={<Navigate to={RouteValues.HOME} />} />
                        </Routes>
                    </BrowserRouter>
                    <Footer />
                </ToastProvider>
            </UserProvider>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    );
};
