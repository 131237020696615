import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { type FC, Fragment } from "react";

import { Button } from "../basic";
import { NavLink } from "../basic/link/navLink";
import { type Route } from "./types";

interface MobileSidebarProps {
    sidebarOpen: boolean;
    setSidebarOpen: (value: boolean) => void;
    routes: Route[];
}

export const MobileSidebar: FC<MobileSidebarProps> = ({ sidebarOpen, setSidebarOpen, routes }) => {
    return (
        <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-transparent" />
                </Transition.Child>

                <div className="fixed inset-0 flex">
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute top-0 flex w-16 justify-center pt-5"></div>
                            </Transition.Child>

                            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-200 px-6 pb-2 ring-1 ring-white/10 dark:bg-gray-900">
                                <div className="flex h-16 shrink-0 items-center justify-between">
                                    <div>
                                        <img className="h-8 w-auto dark:hidden" src="/images/logo-icon.png" alt="AVL" />
                                        <img
                                            className="hidden h-8 w-auto dark:block"
                                            src="images/logo-white-icon.png"
                                            alt="AVL"
                                        />
                                    </div>
                                    <Button
                                        variant="ghost"
                                        className="-m-2.5 p-2.5"
                                        onClick={() => setSidebarOpen(false)}
                                    >
                                        <span className="sr-only">Close sidebar</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </Button>
                                </div>
                                <nav className="flex flex-1 flex-col">
                                    <ul className="-mx-2 flex-1 space-y-1">
                                        {routes.map((route) => (
                                            <SidebarItem route={route} key={route.name} />
                                        ))}
                                    </ul>
                                </nav>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

const SidebarItem: FC<{ route: Route }> = ({ route }) => {
    return (
        <li key={route.name}>
            <NavLink to={route.href} className="!flex-row">
                {route.icon}
                {route.name}
            </NavLink>
        </li>
    );
};
