import { useQuery } from "@tanstack/react-query";
import { type FC, type ReactNode, createContext, useCallback, useMemo, useState } from "react";

import { GridFiltersApi } from "../api/gridFiltersApi";
import { QueryKeys, checkPermission } from "../helpers";
import { type UserDto } from "../models";
import { type UserPermissionFlags } from "../models/permissions";

interface UserContextType {
    user?: UserDto;
    setUser: (user?: UserDto) => void;
    hasPermission: (permission: UserPermissionFlags) => boolean;
    hasCampaigns: boolean;
}

export const UserContext = createContext<UserContextType>({
    user: undefined,
    setUser: () => undefined,
    hasPermission: () => false,
    hasCampaigns: false,
});

interface UserProviderProps {
    children: ReactNode;
}

export const UserProvider: FC<UserProviderProps> = ({ children }) => {
    const [user, setUser] = useState<UserDto | undefined>(undefined);

    const campaignQuery = useQuery({
        queryKey: [QueryKeys.CAMPAIGNS_FILTER],
        queryFn: () => GridFiltersApi.getCampaigns(),
        enabled: !!user,
    });
    const hasCampaigns = useMemo(() => !!campaignQuery.data?.length, [campaignQuery.data]);
    const hasPermission = useCallback((permission: UserPermissionFlags) => checkPermission(user, permission), [user]);

    return (
        <UserContext.Provider value={{ user, setUser, hasPermission, hasCampaigns }}>{children}</UserContext.Provider>
    );
};
