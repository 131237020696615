import { useMemo, useState } from "react";

import { CampaignApi } from "../../api/campaignApi";
import { Severity, formatActive, formatDateOf, logger, splitWordsOnUpperCase } from "../../helpers";
import { QueryKeys, usePropReducer, useTransitionState } from "../../helpers";
import { useMapVerbiage } from "../../hooks/verbiageHooks";
import { type CampaignDto, type CampaignFilter, type IFilter } from "../../models";
import { Column } from "../basic";
import { FilterGroups } from "../gridFilters/types";
import { GridPage } from "../gridPage";
import { SubComponent } from "./subComponent";

export default function CampaignGrid() {
    const [filterData, dispatchFilterData] = usePropReducer({} as CampaignFilter);
    const mapVerbiage = useMapVerbiage();
    const [itemsPerPage, setItemsPerPage] = useTransitionState(10);
    const [currentPage, setCurrentPage] = useTransitionState(1);
    const columns = useMemo(
        () =>
            [
                new Column(mapVerbiage("Campaign"), "name"),
                new Column("Code", "code"),
                new Column("Active", "active").withFormat(formatActive),
                new Column("Client", "clientName"),
                new Column("Type", "type").withFormat(splitWordsOnUpperCase),
                new Column("State", "stateName"),
                new Column("Boundary", "boundary"),
                new Column("Start Date").withFormat(startDate),
                new Column("End Date").withFormat(endDate),
            ] as Column<CampaignDto>[],
        [mapVerbiage],
    );

    const [sortFilter, setSortFilter] = useState<IFilter>({});

    async function getData(signal: AbortSignal) {
        try {
            return await CampaignApi.getCampaigns(signal, itemsPerPage, currentPage, {
                ...filterData,
                ...sortFilter,
            });
        } catch (ex) {
            logger(Severity.Error, ex);
        }
    }

    return (
        <GridPage<CampaignFilter, CampaignDto>
            title="Campaigns"
            filters={filterData}
            updateFilter={dispatchFilterData}
            visibleFilters={[FilterGroups.Segment, FilterGroups.Active, FilterGroups.Date]}
            queryKey={QueryKeys.CAMPAIGNS}
            getData={getData}
            columns={columns}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={(itemsPerPage: number) => setItemsPerPage(itemsPerPage)}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            sortFilter={sortFilter}
            setSortFilter={setSortFilter}
            expandable
            renderSubComponent={(row) => <SubComponent row={row.row} />}
            getRowCanExpand={() => true}
        />
    );
}

function startDate(row?: CampaignDto) {
    return row?.activePeriod?.start ? formatDateOf(row.activePeriod.start) : "";
}

function endDate(row?: CampaignDto) {
    return row?.activePeriod?.end ? formatDateOf(row.activePeriod.end) : "";
}
