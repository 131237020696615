import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { type Row, flexRender } from "@tanstack/react-table";
import { Fragment, type ReactNode } from "react";

import { type CustomTable } from "./dataGrid";

interface DataGridMobileProps<T> {
    table: CustomTable<T>;
    options?: (row: T) => ReactNode;
    rightOptions?: (row: T) => ReactNode;
    renderSubComponent?: (props: { row: Row<T> }) => ReactNode;
    expandable?: boolean;
}

export function DataGridMobile<T = object>(props: DataGridMobileProps<T>) {
    return (
        <div>
            {props.table.getRowModel().rows.map((row) => (
                <div key={row.id} className="w-full">
                    <div className="card mb-4 grid w-full grid-cols-[repeat(2,minmax(auto,auto))] gap-2 p-4">
                        <div />
                        <div>{props.rightOptions && props.rightOptions(row.original)}</div>
                        {row.getVisibleCells().map((cell) => (
                            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                            <Fragment key={cell.id + cell.column.columnDef.header}>
                                <span className="col-1 text-right font-semibold">
                                    {/* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */}
                                    {flexRender(cell.column.columnDef.header, cell.getContext() as any)}
                                </span>
                                <span className="col-2 text-left">
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </span>
                            </Fragment>
                        ))}
                        <div />
                        <div>{props.options && props.options(row.original)}</div>
                        {row.getCanExpand() && props.expandable && props.renderSubComponent && (
                            <div className="col-span-2">
                                {row.getIsExpanded() && getExpandButton(row)}
                                {row.getIsExpanded() && <div>{props.renderSubComponent({ row })}</div>}
                                {getExpandButton(row)}
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
    function getExpandButton(row: Row<T>) {
        return (
            <div className="text-center">
                <button onClick={row.getToggleExpandedHandler()}>
                    {row.getIsExpanded() ? <ChevronUpIcon className="w-8" /> : <ChevronDownIcon className="w-8" />}
                </button>
            </div>
        );
    }
}
