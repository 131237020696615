import { PlusIcon } from "@heroicons/react/24/outline";
import { useContext, useMemo, useState } from "react";

import { FormApi } from "../../api/formApi";
import { UserContext } from "../../contexts/userContext";
import { Severity, formatDateTimeOf, logger } from "../../helpers";
import { QueryKeys, usePropReducer, useTransitionState } from "../../helpers";
import { useMapVerbiage } from "../../hooks/verbiageHooks";
import { type FormFilter, type FormPrintBatchDto, type IFilter } from "../../models";
import { Button, Column, Dialog } from "../basic";
import { FilterGroups } from "../gridFilters/types";
import { GridPage } from "../gridPage";
import { CreatePrintFormBatch } from "./createPrintFormBatch";
import { Options } from "./options";
import { SelectedActions } from "./selectedActions";

export default function FormGrid() {
    const mapVerbiage = useMapVerbiage();
    const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
    const [filterData, dispatchFilterData] = usePropReducer({} as FormFilter);
    const [itemsPerPage, setItemsPerPage] = useTransitionState(10);
    const [currentPage, setCurrentPage] = useTransitionState(1);
    const columns = useMemo(() => {
        const columns = [
            new Column("Campaign", "campaignName"),
            new Column("Created", "createdOn").withFormat(formatDateTimeOf),
            new Column(mapVerbiage("Rep"), "rep"),
            new Column("Size", "size"),
            new Column("Lines", "lines"),
            new Column("Used", "used"),
            new Column("Created By", "createdBy"),
        ] as Column<FormPrintBatchDto>[];

        return columns.filter(Boolean);
    }, [mapVerbiage]);

    const [sortFilter, setSortFilter] = useState<IFilter>({});
    const { user } = useContext(UserContext);

    async function getData(signal: AbortSignal) {
        try {
            return await FormApi.getForms(signal, itemsPerPage, currentPage, {
                ...filterData,
                ...sortFilter,
            });
        } catch (ex) {
            logger(Severity.Error, ex);
        }
    }

    if (!user) return null;

    const initialFormPrintBatch: Partial<FormPrintBatchDto> = {
        campaignId: undefined,
        formTemplateId: 0,
        representativeId: 0,
        size: 1,
        createdByUserId: user.id,
        createdOn: new Date(),
        createdBy: `${user.email}`,
        used: 0,
    };

    return (
        <>
            <GridPage<FormFilter, FormPrintBatchDto>
                title="Print Form Batches"
                actionButton={
                    <Button variant="default" onClick={() => setOpenCreateDialog(true)}>
                        <PlusIcon className="mr-1 w-5" /> New Form Batch
                    </Button>
                }
                filters={filterData}
                updateFilter={dispatchFilterData}
                visibleFilters={[FilterGroups.Campaign, FilterGroups.Date]}
                queryKey={QueryKeys.FORMS}
                getData={getData}
                columns={columns}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={(itemsPerPage: number) => setItemsPerPage(itemsPerPage)}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                sortFilter={sortFilter}
                setSortFilter={setSortFilter}
                options={(row) => <Options data={row} />}
                selectedActions={(rows) => <SelectedActions forms={rows} />}
            />
            <Dialog
                open={openCreateDialog}
                setOpen={setOpenCreateDialog}
                title="Create Form Batch"
                body={
                    <CreatePrintFormBatch
                        printForm={initialFormPrintBatch}
                        setOpen={setOpenCreateDialog}
                        create={true}
                    />
                }
            />
        </>
    );
}
