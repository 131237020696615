import { PlusIcon } from "@heroicons/react/24/outline";
import { useContext, useState } from "react";

import { UsersApi } from "../../api/usersApi";
import { UserContext } from "../../contexts/userContext";
import { Severity, formatActive, formatName, logger } from "../../helpers";
import { QueryKeys, usePropReducer, useTransitionState } from "../../helpers";
import { type IFilter, type UserDto, type UserFilter } from "../../models";
import { UserPermissionFlags } from "../../models/permissions";
import { Button, Column, Dialog } from "../basic";
import { FilterGroups } from "../gridFilters/types";
import { GridPage } from "../gridPage";
import { CreateUpdateUser } from "./createUpdateUser";
import { UserGridDetail } from "./details/userGridDetail";
import { Options } from "./options";
import { SelectedActions } from "./selectedActions";
import { useToggleUsers } from "./useToggleUser";

function showIfNotOnUser(user?: UserDto, code?: keyof UserDto, value?: string) {
    if (!user || !code) return "";
    if (user[code] !== value) return value;
    return "";
}

const initialState: UserFilter = {
    clients: [],
    channels: [],
    states: [],
};

export default function UsersGrid() {
    const { user, hasPermission } = useContext(UserContext);
    const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
    const [filterData, dispatchFilterData] = usePropReducer(initialState);
    const [itemsPerPage, setItemsPerPage] = useTransitionState(10);
    const [currentPage, setCurrentPage] = useTransitionState(1);
    const [sortFilter, setSortFilter] = useState<IFilter>({});
    const toggle = useToggleUsers();

    const columns = [
        new Column("Name").withFormat((u: UserDto) => formatName(u.firstName, u.lastName)),
        new Column("Email", "email"),
        new Column("Client", "clientName").withFormat((i: string) => showIfNotOnUser(user, "clientId", i)),
        new Column("State", "stateName").withFormat((i: string) => showIfNotOnUser(user, "stateId", i)),
        new Column("Channel", "channelName").withFormat((i: string) => showIfNotOnUser(user, "channelId", i)),
        new Column("Status", "active").withFormat(formatActive),
        new Column("Parent", "parent"),
    ] as Column<UserDto>[];

    async function getData(signal: AbortSignal) {
        try {
            return await UsersApi.getUsers(signal, itemsPerPage, currentPage, {
                ...filterData,
                ...sortFilter,
            });
        } catch (ex) {
            logger(Severity.Error, ex);
        }
    }

    if (!user) return null;

    const initialUser: Partial<UserDto> = {
        firstName: "",
        lastName: "",
        email: "",
        channelId: user.channelId,
        clientId: user.clientId,
        stateId: user.stateId,
        active: true,
        isOwner: false,
        permissionFlags: 0,
    };

    return (
        <>
            <GridPage<UserFilter, UserDto>
                title="Users"
                actionButton={
                    hasPermission(UserPermissionFlags.UserManage) ? (
                        <Button variant="default" onClick={() => setOpenCreateDialog(true)}>
                            <PlusIcon className="mr-1 w-5" /> New User
                        </Button>
                    ) : undefined
                }
                filters={filterData}
                updateFilter={dispatchFilterData}
                visibleFilters={[FilterGroups.Segment, FilterGroups.Search, FilterGroups.Active]}
                queryKey={QueryKeys.USERS}
                getData={getData}
                columns={columns}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={(itemsPerPage: number) => setItemsPerPage(itemsPerPage)}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                sortFilter={sortFilter}
                setSortFilter={setSortFilter}
                options={
                    hasPermission(UserPermissionFlags.UserManage)
                        ? (row) => <Options user={row} toggle={toggle} />
                        : undefined
                }
                selectedActions={
                    hasPermission(UserPermissionFlags.UserManage)
                        ? (rows) => <SelectedActions users={rows} />
                        : undefined
                }
                expandable
                getRowId={(row) => row.id.toString()}
                renderSubComponent={(row) => <UserGridDetail row={row.row} />}
                getRowCanExpand={() => true}
            />
            <Dialog
                open={openCreateDialog}
                setOpen={setOpenCreateDialog}
                title="Create User"
                body={<CreateUpdateUser user={initialUser} setOpen={setOpenCreateDialog} create={true} />}
            />
        </>
    );
}
