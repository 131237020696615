import { useQuery } from "@tanstack/react-query";
import { type FC } from "react";

import { GridFiltersApi } from "../../api/gridFiltersApi";
import { QueryKeys } from "../../helpers";

export const Logo: FC = () => {
    const campaignQuery = useQuery({
        queryKey: [QueryKeys.CAMPAIGNS_FILTER],
        queryFn: () => GridFiltersApi.getCampaigns(),
    });

    if (campaignQuery.data?.length === 0)
        return (
            <>
                <img src="/images/logo-white.png" alt="Advanced Verification" className="hidden w-60 dark:block" />
                <img src="/images/logo.png" alt="Advanced Verification" className="w-60 dark:hidden" />
            </>
        );

    return (
        <>
            <img src="/images/logo-white-voterfi.png" alt="VoterFi" className="hidden w-60 dark:block" />
            <img src="/images/logo-voterfi.png" alt="VoterFi" className="w-60 dark:hidden" />
        </>
    );
};
