import { PencilSquareIcon, PlusIcon } from "@heroicons/react/24/outline";
import { useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ScanApi } from "../../api/scanApi";
import { CheckInContext } from "../../contexts/checkInContext";
import { UserContext } from "../../contexts/userContext";
import { Severity, formatDateTimeOf, logger } from "../../helpers";
import { QueryKeys, RouteValues, usePropReducer, useTransitionState } from "../../helpers";
import { useMapVerbiage } from "../../hooks/verbiageHooks";
import { type CheckInFilter, type IFilter } from "../../models";
import { type FormScanBatchDto } from "../../models/formScanBatchDto";
import { Button, Column, Dialog } from "../basic";
import { FilterGroups } from "../gridFilters/types";
import { GridPage } from "../gridPage";
import { CreateCheckIn } from "./createCheckIn";

export default function CheckInGrid() {
    const mapVerbiage = useMapVerbiage();
    const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
    const [filterData, dispatchFilterData] = usePropReducer({} as CheckInFilter);
    const [itemsPerPage, setItemsPerPage] = useTransitionState(10);
    const [currentPage, setCurrentPage] = useTransitionState(1);
    const columns = useMemo(() => {
        const columns = [
            new Column(mapVerbiage("Campaign"), "campaignName").withSortable(true),
            new Column("User", "createdBy").withSortable(true),
            new Column("Direction", "direction"),
            new Column("Started", "createdOn").withSortable(true).withFormat(formatDateTimeOf),
            new Column("Completed", "completedOn").withSortable(true).withFormat(formatDateTimeOf),
            new Column("Count", "size").withSortable(true),
            new Column(mapVerbiage("Rep"), "rep").withSortable(true),
        ] as Column<FormScanBatchDto>[];

        return columns.filter(Boolean);
    }, [mapVerbiage]);
    const [sortFilter, setSortFilter] = useState<IFilter>({});
    const { user } = useContext(UserContext);
    const { batchId, batchSummary } = useContext(CheckInContext);
    const hasPriorBatch = batchId && !batchSummary?.completedOn;

    const navigate = useNavigate();

    async function getData(signal: AbortSignal) {
        try {
            return await ScanApi.getAll(signal, itemsPerPage, currentPage, {
                ...filterData,
                ...sortFilter,
            });
        } catch (ex) {
            logger(Severity.Error, ex);
        }
    }

    if (!user) return null;

    return (
        <>
            <GridPage<CheckInFilter, FormScanBatchDto>
                title="Check In/Out"
                actionButton={
                    <div className="flex gap-2">
                        {hasPriorBatch ? (
                            <Button
                                variant="outline"
                                onClick={() => {
                                    navigate(`/${RouteValues.CHECK_IN}/${batchId}`);
                                }}
                            >
                                <PencilSquareIcon className="mr-1 w-5" /> Previous Check {batchSummary?.direction}
                            </Button>
                        ) : null}
                        <Button variant="default" onClick={() => setOpenCreateDialog(true)}>
                            <PlusIcon className="mr-1 w-5" /> New Check In/Out
                        </Button>
                    </div>
                }
                filters={filterData}
                updateFilter={dispatchFilterData}
                visibleFilters={[FilterGroups.Segment, FilterGroups.Campaign, FilterGroups.Date]}
                queryKey={QueryKeys.CHECK_IN}
                getData={getData}
                columns={columns}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={(itemsPerPage: number) => setItemsPerPage(itemsPerPage)}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                sortFilter={sortFilter}
                setSortFilter={setSortFilter}
            />
            <Dialog
                open={openCreateDialog}
                setOpen={setOpenCreateDialog}
                title="Create Check In/Out"
                body={<CreateCheckIn setOpenCreateDialog={setOpenCreateDialog} />}
            />
        </>
    );
}
